import { React, useEffect, useState } from "react";
import axios from "axios";
import "./DoctorsSlider.css";

function DoctorsSlider() {
  const [doctors, setDoctors] = useState([]);
  useEffect(() => {
    axios
      .get("https://aroor-clinic-api.onrender.com/api/admin/viewAllDoctors")
      .then((res) => {
        setDoctors(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="brandList">
      <ul>
        {doctors.map((item) => {
          return (
            <li>
              <div class="doctorCard yellow">
                <div className="doctorImage">
                  <img src={item.image} class="img-fluid" alt="#" />
                </div>
                <h4>
                  {item.Name}({item.Education})
                </h4>
                <h5>{item.Description}</h5>
              </div>
            </li>
          );
        }, [])}
        {/* <li>
          <div class="doctorCard yellow">
            <div className="doctorImage">
              <img src="images/doctor-1.jpg" class="img-fluid" alt="#" />
            </div>
            <h4>Dr. Lattiesh k Bharathan(DCH,DNB)</h4>
            <h5>General Practitioner</h5>
          </div>
        </li>
        <li>
          <div class="doctorCard yellow">
            <div className="doctorImage">
              <img src="images/doctor-2.jpg" class="img-fluid" alt="#" />
            </div>
            <h4>Dr. Saumya Lattiesh k(DNB)</h4>
            <h5>Paediatrician</h5>
          </div>
        </li> */}
      </ul>
    </div>
  );
}

export default DoctorsSlider;
