import axios from "axios";
import { React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DoctorsSlider from "./DoctorsSlider";
import { userContext } from "../Context/Context";

function Home() {
  const navigate = useNavigate();
  const [blog, setBlogs] = useState([]);
  const { singleBlog, setSingleBlog } = useContext(userContext);

  useEffect(() => {
    axios
      .get("https://aroor-clinic-api.onrender.com/api/admin/viewAllBlog")
      .then((res) => {
        setBlogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function handleSingleBlog(item) {
    setSingleBlog(item);
    navigate("/blogSingle");
  }
  return (
    <div>
      <section class="about-section">
        <div class="container container-custom">
          <div class="row space">
            <div class="col-md-4">
              <div class="service-thumbnail d-flex flex-fill">
                <img
                  src="https://www.ourfamilyclinic.org/images/icons/day-care.png"
                  class="img-fluid"
                  alt="#"
                />
                <div class="service-thumbnail_text">
                  <h4>Specialised Service</h4>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-thumbnail d-flex flex-fill">
                <img
                  src="https://www.ourfamilyclinic.org/images/icons/icon-heart-clinic.png"
                  class="img-fluid"
                  alt="#"
                />
                <div class="service-thumbnail_text">
                  <h4>Pharmacy Services</h4>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="service-thumbnail border-0 d-flex flex-fill">
                <img
                  src="https://www.ourfamilyclinic.org/images/icons/diagnostic.png"
                  class="img-fluid"
                  alt="#"
                />
                <div class="service-thumbnail_text">
                  <h4>Online Booking</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="about-video_block">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <a
                      href="https://www.youtube.com/watch?v=S7qO_9-NJmA"
                      class="video-play-img popup-youtube"
                    >
                      <img
                        src="images/about-img.jpg"
                        class="img-fluid w-100"
                        alt="#"
                      />
                      {/* <div class="video-play-btn">
                        <div class="video-play-icon">
                          <i class="fas fa-play"></i>
                        </div>
                        <span>PLAY VIDEO</span>
                      </div> */}
                    </a>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div
                      class="video-play-text"
                      style={{ textAlign: "justify" }}
                    >
                      <h2>About Us</h2>
                      <p>
                        At <b>The Aroor Clinic</b>, we believe in patient
                        centricity. As we treat you and your caregivers with
                        compassion, care, and understanding, we pledge to
                        provide our patients with the finest results and
                        experiences possible. For us, our patient's needs always
                        come first.
                      </p>
                      <p>
                        With two experienced doctors at your service, we make
                        sure that you receive the best service available. Dr.
                        Lettiesh K Bharathan is our general practitioner with
                        more than 8 years of experience who has treated
                        thousands of patients with this professional expertise.
                        Dr. Saumya Lettiesh is our pediatrician consultant with
                        a diploma in Child Health from Kozhikode Medical College
                        and DNB(pediatrics) from aster Medicity. She has been
                        treating children with her professional medical care, we
                        assure you that your children will walk out of our
                        hospital safe and sound and most importantly, healthy!
                      </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="facilities blue">
                            <h3>1000+</h3>
                            <span>Happy Patients</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="facilities green">
                            <h3>2+</h3>
                            <span>Expert Doctors</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        {/* <div class="col-md-6">
                          <div class="facilities yellow">
                            <h3>10+</h3>
                            <span>Hospital Rooms</span>
                          </div>
                        </div> */}
                        <div class="col-md-6">
                          <div class="facilities gray">
                            <h3>1+</h3>
                            <span>Award Winner</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="space light">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="heading-style1">
                <span>Our Services</span>
                <h2>High Quality Services for You</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="service-slider">
                <div class="service-block yellow">
                  <img src="images/service-icon1.png" alt="#" />
                  <h3>Dental Care</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eius mod tempor incididunt ut labore{" "}
                  </p>
                  <a href="#" class="btn btn-dark">
                    READ MORE
                  </a>
                  <div class="service-bg-icon">
                    <img
                      src="images/services-bg1.png"
                      class="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="service-block green">
                  <img src="images/service-icon2.png" alt="#" />
                  <h3>Eye Care</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eius mod tempor incididunt ut labore
                  </p>
                  <a href="#" class="btn btn-dark">
                    READ MORE
                  </a>
                  <div class="service-bg-icon">
                    <img
                      src="images/services-bg2.png"
                      class="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="service-block blue">
                  <img src="images/service-icon3.png" alt="#" />
                  <h3>Allergic Issue</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eius mod tempor incididunt ut labore
                  </p>
                  <a href="#" class="btn btn-dark">
                    READ MORE
                  </a>
                  <div class="service-bg-icon">
                    <img
                      src="images/services-bg3.png"
                      class="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="service-block green">
                  <img src="images/service-icon3.png" alt="#" />
                  <h3>Allergic Issue</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eius mod tempor incididunt ut labore
                  </p>
                  <a href="#" class="btn btn-dark">
                    READ MORE
                  </a>
                  <div class="service-bg-icon">
                    <img
                      src="images/services-bg4.png"
                      class="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="text-center service-help_link">
                Contact us for better help and services.
                <a href="#">Let’s get started</a>
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section class="space why-choose-block">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12 col-lg-5">
              <h2>
                Why Choose
                <br />The Aroor Clinic?
              </h2>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fas fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>Advanced Care</h5>
                      {/* <span> we provide for you</span> */}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fas fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>Online Appointment</h5>
                      {/* <span>take online appointment</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fas fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>Pharmacy Services</h5>
                      {/* <span>we provide for you</span> */}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fas fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>Home Healthcare</h5>
                      {/* <span>we provide for you</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <a href="#" class="btn btn-dark" tabindex="0">
                    MAKE APPOINTMENT
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6 offset-lg-1">
              <div class="why-choose_right">
                <h2 className="contact-header">
                  Emergency
                  <br /> Contact Us.
                </h2>
                <p>
                  Thank you for choosing our clinic. We look forward to serving
                  you and helping you maintain optimal health. Please don't
                  hesitate to contact us with any questions or to schedule an
                  appointment.
                </p>
                <div class="why-choose_list">
                  <div class="choose-icon">
                    <i class="fas fa-phone-volume"></i>
                  </div>

                  <div class="why-choose_list-content">
                    <span>Call us now</span>
                    <h3>+91 9995909822</h3>
                    <h3>0478-2990224</h3>
                   
                  </div>
                </div>
                <div class="why-choose_list">
                  <div class="choose-icon">
                    <i class="fas fa-envelope-open-text"></i>
                  </div>
                  <div class="why-choose_list-content">
                    <span>Mail us</span>
                    <h3>thearoorclinic@gmail.com</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="news-img-block">
                <img
                  src="images/play-img.png"
                  class="img-fluid w-100"
                  alt="#"
                />
                <a
                  class="video-play-button popup-youtube"
                  href="https://www.youtube.com/watch?v=S7qO_9-NJmA"
                >
                  <span></span>
                </a>
                <div id="video-overlay" class="video-overlay">
                  <a class="video-overlay-close">&times;</a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="video-play-text">
                <h2>Personal Care With Professional Approach</h2>
                <p>
                  We believe that education is an important part of healthcare,
                  so we offer a range of resources and educational materials to
                  help you make informed decisions about your health. We also
                  offer a variety of services to meet your specific needs,
                  including birth control options and treatment for sexually
                  transmitted infections.
                </p>

                <hr />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <span>---- Our Team ----</span>
                <h2>Our Dedicated Doctors</h2>
              </div>
            </div>
          </div>
          <div>
            <DoctorsSlider></DoctorsSlider>
          </div>
        </div>
      </section>

      <section class="bg-img3">
        <div class="container container-custom">
          <div class="row">
            <div class="col-lg-5 offset-lg-7">
              <div class="consultant-content">
                <h2>We Believe in a Healthier You</h2>
                <p style={{ textAlign: "justify" }}> 
                  You are so much more than a patient at <b>The Aroor Clinic</b>. Every
                  effort has been made to prioritize your medical and personal
                  requirements above everything else, starting with the way we
                  structured our patient rooms through the service we provide
                  for you
                </p>

                <a class="btn btn-success">Get a Consultant</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="testimonial pb-0">
        <div class="container container-custom">
          <div class="col-md-12">
            <div class="heading-style1">
              <span>Testimonials</span>
              <h2>What Our Clients Say</h2>
            </div>
            <div class="testi-slider">
              <div class="testimonial-wrap">
                <img
                  src="images/testi-img1.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
              <div class="testimonial-wrap quaternary-br-color">
                <img
                  src="images/testi-img2.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
              <div class="testimonial-wrap">
                <img
                  src="images/testi-img1.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
              <div class="testimonial-wrap quaternary-br-color">
                <img
                  src="images/testi-img2.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section class="space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <span>
                  <i>---- Blog ----</i>
                </span>
                <h2>Latest News & Events</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="blog-wrap">
                {blog.map((items) => {
                  return (
                    <div class="blog-row-block">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="blog-img">
                            <img src={items.image} class="img-fluid" alt="#" />
                          </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-center">
                          <div class="blog-content">
                            <span>
                              {items.blogDate.month} {items.blogDate.day} | By
                              Admin
                            </span>
                            <h3>{items.Name}</h3>
                          </div>
                        </div>
                        <div class="col-md-3 d-flex align-items-center">
                          <div class="blog-read-more">
                            <a
                              onClick={() => handleSingleBlog(items)}
                              class="btn btn-primary"
                            >
                              READ MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div class="blog-row-block">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="blog-img">
                            <img src="http://res.cloudinary.com/dbpxhm5vt/image/upload/v1669724296/Nediyath%20Product%20image/x1d57qjzqgxqb26cvogy.png" class="img-fluid" alt="#" />
                          </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-center">
                          <div class="blog-content">
                            <span>
                              NOV 28 2022 | By
                              Admin
                            </span>
                            <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                          </div>
                        </div>
                        <div class="col-md-3 d-flex align-items-center">
                          <div class="blog-read-more">
                            <a
                              // onClick={() => handleSingleBlog(items)}
                              class="btn btn-primary"
                            >
                              READ MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="text-center mt-5">
                <a
                  onClick={() => navigate("/blog")}
                  class="btn btn-outline-primary"
                >
                  View All Blogs
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
