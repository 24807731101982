import React from "react";
import Footer from "../Footer/Footer";
import SubHeader from "../Header/SubHeader";

function GeneralMedicine() {
  return (
    <div>
      <SubHeader />
      <section class="space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="news-img-block">
                <img
                  src="images/play-img.png"
                  class="img-fluid w-100"
                  alt="#"
                />
                <a
                  class="video-play-button popup-youtube"
                  href="https://www.youtube.com/watch?v=S7qO_9-NJmA"
                >
                  <span></span>
                </a>
                <div id="video-overlay" class="video-overlay">
                  <a class="video-overlay-close">&times;</a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="video-play-text" style={{ textAlign: "justify" }}>
                <h2>Pediatrics</h2>
                <p>
                  At <b>The Aroor Clinic</b>, we understand the importance of providing
                  top-notch pediatric care to your little ones. Our pediatrician
                  is highly trained and experienced in treating children of all
                  ages, from newborns to teenagers. We believe in taking a
                  holistic approach to pediatric care, considering not only
                  physical health but also emotional and developmental
                  well-being. Our pediatrician takes the time to get to know
                  your child and your family, and works with you to create a
                  personalized treatment plan that addresses your child's unique
                  needs. Whether it's for a routine check-up or for managing
                  chronic conditions, we are here to support you and your child.
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default GeneralMedicine;
