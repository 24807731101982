import React from "react";
import GeneralMedicine from "../Components/generalMedicine/GeneralMedicine";

function GeneralMedicin() {
  return (
    <div>
      <GeneralMedicine />
    </div>
  );
}

export default GeneralMedicin;
