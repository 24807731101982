import React from "react";

import SubHeader from "../Header/SubHeader";
function About() {
  return (
    <div>
      <SubHeader></SubHeader>

      {/* <div class="light nav-big">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
            
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto nav-sub">
                                <li class="nav-item ">
                                    <a class="nav-link " href="/"  role="button"  aria-haspopup="true" aria-expanded="false">
                                        Home <AddIcon sx={{fontSize:'small'}}></AddIcon>
                                    </a>
                                    
                                </li>
                                <li class="nav-item ">
                                    <a class="nav-link " href="#"  role="button"  aria-haspopup="true" aria-expanded="false">
                                        Department <AddIcon sx={{fontSize:'small'}}></AddIcon>
                                    </a>
                                    
                                </li>
                                        
                                        <li class="nav-item ">
                                            <a class="nav-link " href="#" 
                                                aria-haspopup="true" aria-expanded="false">
                                                Patients <AddIcon sx={{fontSize:'small'}}></AddIcon>
                                            </a>
                                            
                                        </li>
                                        
                                        <li class="nav-item ">
                                            <a class="nav-link" href="/about" 
                                                role="button" aria-haspopup="true"
                                                aria-expanded="false"> About <AddIcon sx={{fontSize:'small'}}></AddIcon> </a>
                                            
                                        </li>
                                <li class="nav-item ">
                                    <a class="nav-link " href="#"  role="button"  aria-haspopup="true" aria-expanded="false">
                                        Contact Us <AddIcon sx={{fontSize:'small'}}></AddIcon>
                                    </a>
                                    
                                </li>
                            </ul>
                            <ul class="nav-icon-wrap">
                                <li class="nav-item">
                                    <a class="nav-link" href="#"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#"><i class="fab fa-instagram"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#"><i class="fab fa-google-plus-g"></i></a>
                                </li>
                                <li class="nav-item cart-seperate">
                                    <a class="nav-link" href="#"><i class="fas fa-shopping-cart fa-top-search"></i> <span>2</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#"><i class="fas fa-bars"></i></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                   
                </div>
            </div>
        </div>
    </div> */}

      <section class="space sub-header">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-6">
              <div class="sub-header_content">
                <p>ABOUT US</p>
                <br></br>
                <span>
                  <i>Home / About Us</i>
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sub-header_main">
                <h2>About us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="about-section space-mt">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="about-video_block">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <img
                      src="images/about-img.jpg"
                      class="img-fluid w-100"
                      alt="#"
                    />
                    {/* <div class="video-play-btn">
                        <div class="video-play-icon">
                          <i class="fas fa-play"></i>
                        </div>
                        <span>PLAY VIDEO</span>
                      </div> */}
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div
                      class="video-play-text"
                      style={{ textAlign: "justify" }}
                    >
                      <h2>ABOUT US</h2>
                      <p>
                        At <b>The Aroor Clinic</b>, we believe in patient centricity. As we
                        treat you and your caregivers with compassion, care, and
                        understanding, we pledge to provide our patients with
                        the finest results and experiences possible. For us, our
                        patient's needs always come first.
                      </p>
                      <p>
                        With two experienced doctors at your service, we make
                        sure that you receive the best service available. Dr.
                        Lettiesh K Bharathan is our general practitioner with
                        more than 8 years of experience who has treated
                        thousands of patients with this professional expertise.
                        Dr. Saumya Lettiesh is our pediatrician consultant with
                        a diploma in Child Health from Kozhikode Medical College
                        and DNB(pediatrics) from aster Medicity. She has been
                        treating children with her professional medical care, we
                        assure you that your children will walk out of our
                        hospital safe and sound and most importantly, healthy!
                      </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="facilities blue">
                            <h3>1000+</h3>
                            <span>Happy Patients</span>
                          </div>
                        </div>
                        <div class="col-md-6"> 
                          <div class="facilities green">
                            <h3>2+</h3>
                            <span>Expert Doctors</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="facilities gray">
                            <h3>1+</h3>
                            <span>Award Winner</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="space-mb">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-6">
              <div class="video-play-text">
                <span>Who we are -----</span>
                <h2>We Have Advanced Technologies</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eius mod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eius mod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                </p>
                <hr />
                <div class="newsletter-subscribe">
                  <h4>Subscribe to our Newsletter</h4>
                  <div class="newsletter-subscribe_form">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Email"
                    />
                    <a href="#">
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="news-img-block">
                <img src="images/play-img.png" class="img-fluid" alt="#" />
                <a
                  class="video-play-button popup-youtube"
                  href="https://www.youtube.com/watch?v=pBFQdxA-apI"
                >
                  <span></span>
                </a>
                <div id="video-overlay" class="video-overlay">
                  <a class="video-overlay-close">&times;</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section class="space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-4">
              <div class="mission-wrap">
                <h3>Our Mission</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adi pisicing elit, sed
                  do eius mod tempor incid idunt ut labore et dolore magna
                  aliqua.
                </p>
                <a href="#">Read More</a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mission-wrap">
                <h3>Our Vision</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adi pisicing elit, sed
                  do eius mod tempor incid idunt ut labore et dolore magna
                  aliqua.
                </p>
                <a href="#">Read More</a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mission-wrap">
                <h3>Our Values</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adi pisicing elit, sed
                  do eius mod tempor incid idunt ut labore et dolore magna
                  aliqua.
                </p>
                <a href="#">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section class="our-team light space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <span>---- Our Team ----</span>
                <h2>Our Dedicated Doctors</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="doctors-slider">
                <div class="team-img_block yellow">
                  <div class="team-img-socila-block">
                    <img src="images/team1.jpg" class="img-fluid" alt="#" />
                    <ul class="social-icons">
                      <li>
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4>Dr. Mary Joe</h4>
                  <p>Cardiologist Specialist</p>
                </div>
                <div class="team-img_block green">
                  <div class="team-img-socila-block">
                    <img src="images/team2.jpg" class="img-fluid" alt="#" />
                    <ul class="social-icons">
                      <li>
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4>Thomas Jack</h4>
                  <p>Neurology Specialist</p>
                </div>
                <div class="team-img_block blue">
                  <div class="team-img-socila-block">
                    <img src="images/team3.jpg" class="img-fluid" alt="#" />
                    <ul class="social-icons">
                      <li>
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4>Dr. Mary Joe</h4>
                  <p>Cardiologist Specialist</p>
                </div>
                <div class="team-img_block yellow">
                  <div class="team-img-socila-block">
                    <img src="images/team4.jpg" class="img-fluid" alt="#" />
                    <ul class="social-icons">
                      <li>
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4>Dr. Hessy Hanna</h4>
                  <p>Radiology Specialist</p>
                </div>
                <div class="team-img_block blue">
                  <div class="team-img-socila-block">
                    <img src="images/team5.jpg" class="img-fluid" alt="#" />
                    <ul class="social-icons">
                      <li>
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4>Dr. Mary Joe</h4>
                  <p>Cardiologist Specialist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section class="testimonial">
        <div class="container container-custom">
          <div class="col-md-12">
            <div class="heading-style1">
              <span>Testimonials</span>
              <h2>What Our Clients Say</h2>
            </div>
            <div class="testi-slider">
              <div class="testimonial-wrap">
                <img
                  src="images/testi-img1.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
              <div class="testimonial-wrap quaternary-br-color">
                <img
                  src="images/testi-img2.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
              <div class="testimonial-wrap">
                <img
                  src="images/testi-img1.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
              <div class="testimonial-wrap quaternary-br-color">
                <img
                  src="images/testi-img2.jpg"
                  class="img-fluid testi-img-icon"
                  alt="#"
                />
                <ul>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                </ul>
                <p>
                  <span>L</span>orem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation.
                </p>
                <span class="testi-name">Mary Jane (ceo)</span>
                <div class="testi-styled-bg">
                  <img
                    src="images/testi-side-img_05.png"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section class="counter">
        <div class="container container-custom">
          <div class="row">
            <div class="col-sm-4 col-md-3 col-lg-3">
              <div class="counter-block">
                <img src="images/counter1.png" alt="#" />
                <div class="counter-text">
                  <h2>2+</h2>
                  <p>Expert Doctors</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-md-3 col-lg-3">
              <div class="counter-block">
                <img src="images/counter2.png" alt="#" />
                <div class="counter-text">
                  <h2>1000+</h2>
                  <p>Happy Patients</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-md-3 col-lg-3">
              <div class="counter-block">
                <img src="images/counter3.png" alt="#" />
                <div class="counter-text">
                  <h2>5+</h2>
                  <p>Award Winner</p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-end">
              <div class="counter-btn_block">
                <a href="#" class="btn btn-success">
                  BOOK NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
