import React from "react";
import ServicesPage from "../Components/services/Services";

function ServicePage() {
  return (
    <div>
      <ServicesPage />
    </div>
  );
}

export default ServicePage;
